import React, { useState } from 'react';
import dayjs from 'dayjs';
import { DateRangePicker, DropdownSelector, SalesRankingView, StatisticsView, WithSidebar } from '@/components';
import { Analytics, Store } from '@/entities';
import { OrganizationView, SalesView } from '@/partials';
import { useAnalytics, useAppContext } from '@/hooks';
import { numberFormat, price } from '@/utils';
import styles from '@/styles/pages/index.module.scss';

const page: React.FC = () => {
  const { stores, loading, error } = useAppContext();

  const [selectedStore, setSelectedStore] = useState<Store>();
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(dayjs().startOf('day').subtract(6, 'day').toDate());
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(dayjs().endOf('day').toDate());
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);
  const {
    analytics,
    loading: analyticsLoading,
    error: analyticsError,
  } = useAnalytics(selectedStore?.id, selectedStartDate, selectedEndDate);

  const sales = analytics ? sumUp(analytics.statisticsChart.earnings.platform) : 0;
  const orders = analytics ? sumUp(analytics.statisticsChart.order.platform) : 0;
  const cancels = analytics ? sumUp(analytics.statisticsChart.canceledOrder.platform) : 0;

  const HeaderView = () => {
    const ALL_STORE = 'すべての店舗';
    const selectableStores = stores || [];

    return (
      <header>
        <DropdownSelector
          placeholder=""
          options={[ALL_STORE].concat(selectableStores.map((store) => store.name))}
          currentIndex={
            selectedStore != undefined
              ? selectableStores.findIndex((store) => store.id == selectedStore.id) + 1
              : 0
          }
          onSelect={(option) => {
            const store =
              option != ALL_STORE
                ? selectableStores.find((store) => store.name == option)
                : undefined;
            setSelectedStore(store);
          }}
        />
        <div className={styles.header_right}>
          <div className={styles.picker}>
            <DateRangePicker
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              focusedInput={focusedInput}
              setFocusedInput={setFocusedInput}
              onDatesChange={(startDate, endDate) => {
                startDate && setSelectedStartDate(startDate);
                endDate && setSelectedEndDate(endDate);
              }}
              maxMonths={12}
            />
          </div>
        </div>
      </header>
    );
  };

  return <WithSidebar loading={loading} error={error || analyticsError}>
    <div className={styles.wrapper}>
      <HeaderView />
      <section>
        <div className={styles.statistics}>
          <StatisticsView title="売上" value={price(sales ?? 0)} loading={analyticsLoading}/>
          <StatisticsView
            title="平均店舗売上"
            value={price(sales ? sales / (stores?.length ?? 1) : 0)}
            loading={analyticsLoading}
          />
          <StatisticsView title="オーダー数" value={numberFormat(orders ?? 0)} loading={analyticsLoading}/>
          <StatisticsView title="キャンセル数" value={numberFormat(cancels ?? 0)} loading={analyticsLoading}/>
          <StatisticsView title="平均単価" value={price(orders && sales ? sales / orders : 0)} loading={analyticsLoading}/>
        </div>
        <div>
          <SalesView statisticsChart={analytics?.statisticsChart} loading={analyticsLoading} />
          <OrganizationView statisticsChart={analytics?.statisticsChart}  loading={analyticsLoading} />
          <div className={styles.ranking}>
            {!selectedStore && (
              <div className={styles.ranking_chart}>
                <SalesRankingView categoryName="店舗" data={analytics?.salesRanking?.store} loading={analyticsLoading} />
              </div>
            )}
            <div className={styles.ranking_chart}>
              <SalesRankingView categoryName="ブランド" data={brandRanking(analytics)} loading={analyticsLoading} />
            </div>
            <div className={styles.ranking_chart}>
              <SalesRankingView categoryName="商品" data={analytics?.salesRanking?.item} loading={analyticsLoading} />
            </div>
          </div>
        </div>
      </section>
    </div>
  </WithSidebar>;
};

const sumUp = (platforms: { data: { v: number }[]; }[]) =>
  platforms.reduce((acc, platform) => acc + platform.data.reduce((acc, data) => acc + data.v, 0), 0);

const brandRanking = (analytics?: Analytics) =>
  analytics?.statisticsChart.earnings.brand
    ?.reduce(
      (acc: { title: string; sales: number }[], data) => [
        ...acc,
        { title: data.name, sales: data.data.reduce((acc, value) => acc + value.v, 0) },
      ],
      [],
    )
    .sort(
      ({ sales: salesL }: { sales: number }, { sales: salesR }: { sales: number }) =>
        salesR - salesL,
    );

export default page;
